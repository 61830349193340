@import "@ukho/admiralty-core/styles/core.css";
@import "style-variables";
@import "bulma/bulma";

.element-title {
  font-family: $admiralty-font-type;
  color: $admiralty-blue;
  font-weight: 400;
  font-size: 21px;
  line-height: 30px;
  margin-bottom: 12px;
}

.sub-element-title {
  font-family: $admiralty-font-type;
  color: #333;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 12px;
}

.descriptive-text {
  color: $admiralty-blue;
  text-align: right;
  font-size: 1.3125rem;
  font-family: $admiralty-font-type;
  font-style: normal;
  font-weight: 300;
  line-height: 1.875rem;
}

.display-contents {
  display: contents;
}

.custom-wrapper {
  position: relative;

  .vertical-strip {
    position: absolute;
    left: 0.1rem;
    background: $colour-off-white;
    height: -webkit-fill-available;
    width: 6px;
  }
}

a {
  cursor: pointer;
  text-decoration: underline;
  background-color: transparent;
  color: $admiralty-blue
}

.dot-new {
  height: 12px;
  width: 12px;
  background-color: #4CBB17;
  border-radius: 50%;
  display: inline-block;
}

.dot-pending {
  height: 12px;
  width: 12px;
  background-color: #E47311;
  border-radius: 50%;
  display: inline-block;
}

.main-heading {
  padding-top: 5.625rem;
}

.form-wrapper {
  padding-top: 5.625rem;
  padding-bottom: 5.625rem;
}

.secondary-heading {
  padding-bottom: 1.875rem;
}

.secondary-heading-small {
  padding-bottom: .5rem;
}

.hint-text {
  margin-bottom: 12px;
}

.input-wrapper {
  display: block;
  margin-bottom: 1.875rem;
}

.section-wrapper {
  margin-top: .125rem;
  display: block;
}

.section-description {
  margin-top: .5rem;
  margin-bottom: 1.875rem;
}

.back-link {
  display: block;
  margin-top: 2.812rem;
  margin-bottom: 2.812rem;
}

.mg-b-2 {
  margin-bottom: 1.875rem;
}

.mg-t-2 {
  margin-top: 1.875rem;
}

.mg-b-5 {
  margin-bottom: 5.625rem;
}

.mg-t-5 {
  margin-top: 5.625rem;
}

.mg-y-5 {
  margin-top: 5.625rem;
  margin-bottom: 5.625rem;
}

.pad-t-2 {
  padding-top: 1.875rem;
}
